<template>
  <div>
    <PageHeader :key="globalLanguage" :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <b-row>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Subject"
                rules="required"
              >
                <TextInput
                  v-model="form.subject"
                  :label="$t('Subject')"
                  :tooltipMessage="$t('Subject')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Status"
                rules="required"
              >
                <label class="input-label form-label"
                  ><span style="color: red">*</span>&nbsp;{{ $t("Status") }}
                </label>
                <MultiSelectInput
                  style="z-index: 9999"
                  v-model="form.active"
                  :key="form.active"
                  :options="statusOptions"
                  :multiple="false"
                  label="name"
                  trackBy="value"
                  :required="true"
                  placeholder="Select Option"
                >
                </MultiSelectInput>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider
                #default="{ errors }"
                name="Body"
                rules="required"
              >
                <label class="input-label form-label"
                  ><span style="color: red">*</span>&nbsp;{{ $t("Body") }}
                </label>
                <vue-editor v-model="form.body"></vue-editor>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end gap-2">
        <router-link
          to="/mail-templates"
          class="d-flex align-items-center gap-1 btn btn-secondary"
        >
          <feather-icon icon="XIcon" size="12" />
          <span>{{ $t("Cancel") }}</span>
        </router-link>
        <b-button
          variant="primary"
          @click="store"
          class="d-flex align-items-center gap-1"
          v-if="$can(`${$route.meta.permission}.create`)"
          :class="{ 'cursor-not-allowed': isFormInvalid }"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Save and Proceed") }}</span>
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import TextAreaInput from "@/components/TextareaInput.vue";
import { VueEditor } from "vue2-quill-editor";
import LoadingButton from "@/components/LoadingButton.vue";
import PageHeader from "@/components/PageHeader.vue";
import { mapGetters } from "vuex";
import { getEditorContentLength } from "@/utils/helpers";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
export default {
  computed: {
    ...mapGetters(["errors", "isLoading"]),
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("Mail Templates"),
          to: "/mail-templates",
        },
        {
          text: this.$t("Create"),
          active: true,
        },
      ];
    },
    isFormInvalid() {
      return !this.form.subject || !this.form.active || !this.form.body;
    },
  },
  components: {
    LoadingButton,
    TextInput,
    MultiSelectInput,
    TextAreaInput,
    VueEditor,
    PageHeader,
    ValidationProvider,
    ValidationObserver,
  },
  remember: "form",
  data() {
    return {
      parsedHtml: "",
      form: {
        subject: "",
        active: {
          name: "Active",
          value: 1,
        },
        body: "",
      },
      statusOptions: [
        {
          name: "Active",
          value: 1,
        },
        {
          name: "Inactive",
          value: 0,
        },
      ],
    };
  },
  methods: {
    // updateDescription(event) {
    //   this.form.body = event;
    //   this.parsedHtml = event;
    // },
    async store() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          try {
            const length = getEditorContentLength(this.form.body);
            if (length == 0) {
              this.form.body = "";
            }
            this.$store.commit("isLoading", true);
            await this.$store.dispatch("mailTemplates/create", {
              ...this.form,
              active: this.form.active.value,
              body: this.form.body,
            });
            await this.$store.dispatch("mailTemplates/list");
            this.$router.push("/mail-templates");
          } catch (e) {
          } finally {
            this.$store.commit("isLoading", false);
          }
        }
      });
    },
  },
};
</script>
